<template>
    <div class="add_address_wrap">
        <Form @submit="handleSave">
            <Cell-Group class="first">
                <Field
                    v-model.trim="receiver.name"
                    required
                    label="收货人"
                    input-align="right"
                    label-class="self_label"
                    placeholder="请输入真实姓名" />
                <!-- :rules="[ { required: true, message: '请填写您的手机号码！' },{ pattern: /^1\d{10}$/, message: '手机号码格式错误！'}]" -->
                <Field
                    v-model.trim="receiver.tel"
                    @blur="verifyPhone"
                    :maxlength="11"
                    required
                    type="number"
                    label="手机号"
                    input-align="right"
                    label-class="self_label"
                    placeholder="请填写您的手机号" />
            </Cell-Group>
            <Cell-Group>
                <cell @click="handleSelectAddress">
                    <template #title>
                        <div class="selectValue">
                            <div class="content">
                                <div
                                    v-if="!searchValue"
                                    class="noneValue">
                                    <img
                                        class="left_icon"
                                        src="./position.png"
                                        alt="">
                                    <span
                                        class="custom-title"
                                        style="color: #3679f0;">选择地址</span>
                                </div>
                                <div
                                    v-if="searchValue"
                                    class="haveValue">
                                    <img
                                        class="left_icon"
                                        src="./position.png"
                                        alt="">
                                    <div class="value_txt">
                                        <span
                                            class="custom-title"
                                            style="color: #3679f0;">{{ searchValue }}</span>
                                        <div class="address_txt">
                                            {{ receiver.address }}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="icon_img">
                                <img
                                    :src="require('@img/arrow_right_grey.png')"
                                    class="icon_arrow"
                                    alt="">
                            </div>
                        </div>

                    <!-- <van-tag type="danger">标签</van-tag> -->
                    </template>
                </cell>

                <Field
                    v-model.trim="receiver.detailAddress"
                    required
                    maxlength="40"
                    label="门牌号"
                    input-align="right"
                    label-class="self_label"
                    placeholder="请输入门牌号" />
            </Cell-Group>
            <!-- @clickEvent="handleSave" -->
            <Btn-Bottom
                native-type="submit"
                title="保存" />
        </Form>
        <Dialog
            v-model.trim="showDialog"
            :confirm-button-color="'#F76D32'"
            @confirm="handleConfirm"
            confirm-button-text="知道了"
            width="280px">
            <div class="tip">
                {{ tip }}
            </div>
        </Dialog>
    </div>
</template>

<script>
import {
    Form, CellGroup, Cell, Field, Dialog,
} from 'vant';
import BtnBottom from '@/components/btn-bottom';
import { mapMutations } from 'vuex';

// import WxTool from '@/utils/wx-tool';

export default {
    components: {
        Form,
        CellGroup,
        Field,
        Cell,
        BtnBottom,
        Dialog: Dialog.Component,

    },
    data() {
        return {
            receiver: {
                name: '',
                tel: '',
                address: '',
                detailAddress: '',
                axis: {
                    latitudes: '',
                    longitudes: '',
                },
            },
            searchValue: '',
            tip: '',
            showDialog: false,
        };
    },
    created() {
        const { QQAddress, consigneeInfo } = this.$store.state.consignee;

        if (this.$store.state.consignee.consigneeInfo.name || this.$store.state.consignee.consigneeInfo.tel || this.$store.state.consignee.consigneeInfo.detailAddress) {
            this.receiver = { ...consigneeInfo };
        }
        if (QQAddress.address) {
            this.receiver.address = QQAddress.address;
            this.searchValue = QQAddress.title;
        }

        console.log(this.$store.state.consignee, '---1');
    },
    methods: {
        ...mapMutations('consignee', [
            'updateConsigneeInfo',
        ]),
        verifyPhone() {},
        handleSelectAddress() {
            console.log(this.receiver, 'handleSelectAddress');
            this.updateConsigneeInfo({ ...this.receiver });
            this.$router.push({
                path: '/search-address',
            });
            // const tool = WxTool.getInstance()
            // // const tool1 = tool.getInstance()
            // tool.getLocation(res => {
            //     console.log(123, res)
            //     tool.getCurrentCity(res.latitude, res.longitude, res2 => {
            //         console.log(666, res2)
            //     })
            // })
            // console.log('tool', tool)
        },
        handleSave() {
            if (!this.receiver.name) {
                this.tip = '请填写收货人姓名';
                this.showDialog = true;
                return;
            }
            if (!this.receiver.tel) {
                this.tip = '请填写收货人手机号';
                this.showDialog = true;
                return;
            }
            const re = /^1\d{10}$/;
            if (!(re.test(this.receiver.tel))) {
                this.tip = '手机号码格式错误';
                this.showDialog = true;
                return;
            }
            if (!this.receiver.address) {
                this.tip = '请填写收货人地址';
                this.showDialog = true;
                return;
            }

            if (!this.receiver.detailAddress) {
                this.tip = '请填写收货人门牌号';
                this.showDialog = true;
                return;
            }
            console.log(this.receiver, '---this.receiver');
            this.updateConsigneeInfo({ ...this.receiver });
            if (this.$route.query.formPage === 'sourceToPay') {
                this.$router.push({
                    name: 'sourceToPay',
                });
            } else {
                this.$router.push({
                    name: 'pay',
                });
            }
        },
        handleConfirm() {
            this.showDialog = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.add_address_wrap {
    padding-top: 8px;
    /deep/ .self_label {
        font-size: 16px;
        // font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333;
    }
    /deep/ .van-cell {
        padding-right: 42px;
    }
    .selectValue {
        display: flex;
        flex-direction: row;
        .content {
            flex: 1;
            .noneValue {
                display: flex;
                align-items: center;
            }
            .haveValue {
                display: flex;
                align-items: center;
            }
            .left_icon {
                width: 18px;
                height: 18px;
                margin-right: 6px;
            }
            .address_txt {
                font-size: 12px;
                font-weight: 400;
                color: #62636c;
                line-height: 12px;
                margin-top: 8px;
            }
        }
        .icon_img {
            display: flex;
            align-items: center;
            margin-right: -24px;
            .icon_arrow {
                width: 12px;
                height: 12px;
            }
        }
    }
    .first {
        margin-bottom: 8px;
    }
    .tip {
        padding: 24px 22px;
        font-size: 16px;
        // font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000;
        line-height: 25px;
        background: #fff;
        text-align: center;
    }
}
</style>
